import dayjs from 'dayjs'
import { useCallback, useContext, useEffect, useState } from 'react'

import GlobalContext from './GlobalContext.tsx'
import MyMarkdown from './Markdown.tsx'

export const Messages = (props: { userId: string | null }) => {
  const [messages, setMessages] = useState<any[]>([])
  const { host, org } = useContext(GlobalContext)

  const fetchData = useCallback(async () => {
    if (props.userId) {
      fetch(host + `/${org}/messages?uID=${props.userId}`, {
        method: 'get',

        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {
          const messages = await response.json()
          if (Array.isArray(messages)) {
            setMessages(messages)
          }
        })
        .catch(error => {
          console.log('Messages error', error)
          setMessages([])
        })
    }
  }, [props.userId, host, org])

  useEffect(() => {
    const timeout = setInterval(() => {
      fetchData()
    }, 1000 * 30) // check messages every 30 seconds

    fetchData()
    return () => {
      clearInterval(timeout)
    }
  }, [fetchData])

  if (!props.userId || messages.length === 0) {
    return null
  }
  return (
    <div className="resultBox" style={{ marginBottom: 20 }}>
      <div>
        <h5 className={'h5'}>Mitteilungen von {import.meta.env.VITE_SITE_NAME}: </h5>

        {messages.map((message: any) => {
          return (
            <div key={message.id} style={{ paddingTop: '1em' }}>
              <h6 style={{ fontStyle: 'italic' }} className={'h6'}>
                Vom {dayjs(message.created).format('D.M.YYYY HH:mm')}:
              </h6>
              <div>
                <MyMarkdown markdown={message.message} />
              </div>
            </div>
          )
        })}
      </div>
      <hr />
    </div>
  )
}
